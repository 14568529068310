import React, { useState, useEffect } from 'react';
import appUrl from "../../../../constants/appUrl";
import { Button } from 'primereact/button';
import { FilterMatchMode } from "primereact/api";
import { Dialog } from 'primereact/dialog';
import ViewAttachment from '../components/ViewAttachment';
import AddEditProcessInformation from '../../ProcessInformation/components/AddEditProcessInformation';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/laoder';
import CustomDescription from '../../../components/CustomDescription';
import CustomVideo from '../../../components/CustomVideo';
import { useHistory } from 'react-router-dom';
const UserProcess = ({ searchQuery }) => {
    console.log("search key",searchQuery)

   
    const [processList, setProcessList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [dialog, setDialog] = useState();
    const [editable, setEditable] = useState();
    const [rowselect, setRowselect] = useState(null)
    const [res, setRes] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [descriptionDialog, setDescriptionDialog] = useState(false);
    const [descriptionContent, setDescriptionContent] = useState('');
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);
    
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const GetAllProcess = async () => {
        const token = localStorage.getItem('token')
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/Process/GetAllProcess`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setProcessList(data);
            setLoading(false);;
        } catch (error) {
            error('Error fetching processes:', error);
        }
    };
    const onHide = () => {
        setDialog(false)
    }

    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                processList.filter((item) =>
                    item.policyPrefix?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                    item.processName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                    item.channelName?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(processList);
        }
    }, [searchQuery, processList]);

    const isAdmin = localStorage.getItem('admin')
    const actionDocument = (rowData) => {
        const documentsToDownload = rowData.processAttachments.filter(attachment =>
            attachment.attachmentPath.endsWith('.docx') ||
            attachment.attachmentPath.endsWith('.pdf') ||
            attachment.attachmentPath.endsWith('.xlsx')
        );

        const documentPaths = documentsToDownload.map(document => document.attachmentPath);
        const hasImage = rowData.processAttachments.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
        const hasVideo = rowData.processAttachments.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                {documentsToDownload.length > 0 && (
                    <Button
                        icon="pi pi-download"
                        className="custom-btn-edit"
                        onClick={() => downloadDocuments(documentPaths)}
                    />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.processAttachments.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
                {hasVideo && (
                    <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
                        setSelectedVideoAttachment(rowData.processAttachments.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg')));
                        setVideoAttachmentDialog(true);
                    }} />
                )}
                {(documentsToDownload.length === 0 && !hasImage && !hasVideo) && (
                    <Button
                        // icon="pi pi-question"
                        label='-'
                        className="custom-btn-edit"
                        tooltip="No attachment available"
                    />
                )}
            </div>
        );
    }
    const onHideVideoAttachment = () => {
        setVideoAttachmentDialog(false);
    };


    const downloadDocuments = async (documentPaths) => {
        try {
            for (let i = 0; i < documentPaths.length; i++) {
                const documentPath = documentPaths[i];
                const response = await fetch(`${appUrl.baseUrl}${documentPath}`);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '');
                link.setAttribute('target', '_blank');
                link.click();
                URL.revokeObjectURL(url);
                await new Promise(resolve => setTimeout(resolve, 1000)); // Delay each download by 1 second
            }
        } catch (error) {
            console.error("Error downloading documents:", error);
            toast.error("Failed to download documents");
        }
    };



    const accept = async (rowData) => {
        try {
            const response = await axios.delete(`${appUrl.baseUrl}api/Process/DeleteProcessByID/${rowData.processId}/${rowData.channelId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };
    const reject = (rowData) => {
        return
    }
    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>

                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this Process?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(rowData),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
            </div>
        );
    }
    const editAction = async (rowData) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(`${appUrl.baseUrl}api/Process/GetProcessByProcessId/${rowData.processId}/${rowData.channelId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = response.data;
            setEditable(true);
            setRowselect(data);
            setDialog(true);
        } catch (error) {
            error("Error fetching outage data by ID:", error);
            toast.error("Failed to fetch data for editing");
        }
    };
    const showDescription = (description) => {
        setDescriptionContent(description);
        setDescriptionDialog(true);
    };
    const onHideDescriptionDialog = () => {
        setDescriptionDialog(false);
    };

    useEffect(() => {
        GetAllProcess();
    }, [res]);

    return (
        <div>
        <ConfirmDialog />
            <ToastContainer />
            <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit Process " : "Add Process"} style={{ width: "60vw" }}>
                <AddEditProcessInformation dialog={dialog} setRes={setRes} setDialog={setDialog} editable={editable} rowData={rowselect} onHide={onHide} />
            </Dialog>

            <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={descriptionDialog} onHide={onHideDescriptionDialog} header="Description" style={{ width: '50vw' }}>
                <CustomDescription description={descriptionContent} />
            </Dialog>


            {isAdmin === 'true' || isAdmin === true ? (
                <div className='grid'>
                    <div className='xl:col-10 md:col-8 sm:col-8 col-4'>
                        <h5 style={{ fontWeight: "bold" }}>Process</h5>
                    </div>

                    <div className='xl:col-2 md:col-4 sm:col-4 col-8'>
                        <Button className="custom-btn w100" label="+ Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                    </div>
                </div>
            ) :
                <div className='grid'>
                    <div className='md:col-10'>
                        <h5 style={{ fontWeight: "bold" }}>Process</h5>
                    </div>
                    <div className='md:col-2'>

                    </div>

                </div>
            }


            <div className='grid'>
                <div className='md:col-12 overflowX' >
                    {loading && <Loader />}
                    <div className='table-custom'>
                        <DataTable
                            value={filteredData}
                            scrollable
                            scrollHeight="600px"
                            rows={9}
                            paginator
                        >
                            <Column field='productName' header="Product " />
                            <Column header="Channel" className='pointer' body={(rowData) => (
                                <span title={rowData.channelName}>
                                    {rowData.channelName.length > 15 ? `${rowData.channelName.slice(0, 15)}...` : rowData.channelName}
                                </span>
                            )} />
                            {/* <Column field='processName' header="Process " /> */}
                            <Column header="Process" className='pointer' body={(rowData) => (
                                <span title={rowData.processName}>
                                    {rowData.processName.length > 15 ? `${rowData.processName.slice(0, 15)}...` : rowData.processName}
                                </span>
                            )} />
                            <Column header="Prefix" className='pointer' body={(rowData) => (
                                <span title={rowData.policyPrefix}>
                                    {rowData.policyPrefix.length > 15 ? `${rowData.policyPrefix.slice(0, 15)}...` : rowData.policyPrefix}
                                </span>
                            )} />
                            <Column
                                header="Description"
                                body={(rowData) => (
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            showDescription(rowData.description);
                                        }}
                                        style={{ cursor: 'pointer', color: '#00b0ab', textDecoration: 'underline' }}
                                    >
                                        View Description
                                    </a>
                                )}
                            />
                            <Column field='createdDate' header="Created Date / Time"
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })} />
                            <Column
                                field="updatedDate"
                                header="Updated Date/Time"
                                body={(rowData) => {
                                    if (!rowData.updatedDate) {
                                        return '-';
                                    }
                                    return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    });
                                }}
                            />

                            <Column header="Attachments" body={actionDocument} style={{ minWidth: '12rem' }} />
                            {isAdmin === 'true' || isAdmin === true ? (
                                <Column header="Action" body={actionTemplate} className='right-border' style={{ minWidth: '10rem' }} />
                            ) : null}
                        </DataTable>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default UserProcess;
