import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AddEditNew from '../components/AddEditNew';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import appUrl from "../../../../constants/appUrl";
import { Dialog } from 'primereact/dialog';
import { SplitButton } from 'primereact/splitbutton';
import { Card } from 'primereact/card';
import banner from "../../../../../assets/KbIcons/Banner1.png";
import banner2 from "../../../../../assets/KbIcons/Banner2.png";
import AddEditAdminApp from '../../AdminZAppJourney/components/AddEditAdminApp';
import AddEditAdminZTraining from '../../AdminZTrainingMaterial/components/AddEditAdminZTraining';
import AddEditAdminProduct from '../../AdminZProduct/components/AddEditAdminProduct';
import AddEditAdminTip from '../../AdminTipOfDay/components/AddEditAdminTip';
import AddEditAdminOutage from '../../AdminOutage/component/AddEditAdminOutage';
import AddEditAdminDisclaimer from '../../AdminDisclaimer/compoenets/AddEditAdminDisclaimer';
import AddEditAdminDashboardSlider from '../../AdminDashboardSlider/components/AddEditAdminDashboardSlider';
import AddEditSocialMedia from '../../SocialMedia/components/AddEditSocialMedia';
import AddEditComplaints from '../../ComplainHandling/components/AddEditComplaints';
import AddEditFaq from '../../faqs/components/AddEditFaq';
import Product from '../../../components/Product';
import AddEditProcessInformation from '../../ProcessInformation/components/AddEditProcessInformation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
const Dashboard = () => {
    const history = useHistory();
    const [dialog, setDialog] = useState();
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [editable, setEditable] = useState();
    const [tipsList, setTipsList] = useState([]);
    const [headerText, setHeaderText] = useState('');

    const [outageList, setOutageList] = useState();
    const [productList, setProductList] = useState();
    const [imagesList, setImagesList] = useState([]);
    const [imagesBanner, setImagesBanner] = useState([]);
    // Array of objects containing content for each card
    const cards = [
        { id: 1, content: "Call Center", para: "Connecting. Resolving. Empowering", path: "/callcenter" },
        { id: 2, content: "Social Media", para: "Seamless. Intuitive. Empowering", path: "/socialmedia" },
        { id: 3, content: "In-App", para: "Connecting. Engaging. Inspiring", path: "/inapp" },

    ];
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true
    };
    // const onHide = () => {
    //     setDialog(false)
    // }
    const GetAllBanners = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/ImageSlider/GetPromotionImages`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setImagesBanner(data)

        } catch (error) {
            error('Error fetching :', error);
        }
    }
    const GetAllImages = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/ImageSlider/GetAllImages`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setImagesList(data)

        } catch (error) {
            error('Error fetching :', error);
        }
    }
    const GetAllTips = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Tips/GetTips`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setTipsList(data)

        } catch (error) {
            error('Error fetching components:', error);
        }
    }
    const GetAllProducts = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setProductList(data)

        } catch (error) {

        }
    }
    const GetOutageDegradation = async () => {
        const token = localStorage.getItem('token')
        console.log('token', token)
        try {
            const response = await fetch(`${appUrl.baseUrl}api/OutageDegradation/GetAllOutageDegradation`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setOutageList(data)
        }
        catch (error) {

        }
    }

    const items = [
        {
            label: 'Process',
            command: () => {
                setSelectedComponent(<AddEditProcessInformation onHide={onHide} />);
                setHeaderText('Add New Process');
                setDialogVisible(true);
            }
        },
        {
            label: 'Product',
            command: () => {
                setSelectedComponent(<AddEditAdminProduct onHide={onHide} />);
                setHeaderText('Add New Product');
                setDialogVisible(true);
            }
        },
        {
            label: 'App Journey',
            command: () => {
                setSelectedComponent(<AddEditAdminApp onHide={onHide} />);
                setHeaderText('Add New App Journey');
                setDialogVisible(true);
            }
        },
        {
            label: 'Training Material',
            command: () => {
                setSelectedComponent(<AddEditAdminZTraining onHide={onHide} />);
                setHeaderText('Add New Training Material');
                setDialogVisible(true);
            }
        },
        {
            label: 'Tip of the day',
            command: () => {
                setSelectedComponent(<AddEditAdminTip onHide={onHide} />);
                setHeaderText('Add New Tip of the Day');
                setDialogVisible(true);
            }
        },
        {
            label: 'Outages / Degradations',
            command: () => {
                setSelectedComponent(<AddEditAdminOutage onHide={onHide} />);
                setHeaderText('Add New Outages / Degradations');
                setDialogVisible(true);
            }
        },
        {
            label: 'Outages / Disclaimer',
            command: () => {
                setSelectedComponent(<AddEditAdminDisclaimer onHide={onHide} />);
                setHeaderText('Add New Outages / Disclaimer');
                setDialogVisible(true);
            }
        },
        {
            label: 'Dashboard Slider',
            command: () => {
                setSelectedComponent(<AddEditAdminDashboardSlider onHide={onHide} />);
                setHeaderText('Add New Dashboard Slider');
                setDialogVisible(true);
            }
        },
        {
            label: 'Tutorials and Videos',
            command: () => {
                setSelectedComponent(<AddEditSocialMedia onHide={onHide} />);
                setHeaderText('Add New Tutorials and Videos');
                setDialogVisible(true);
            }
        },
        {
            label: 'Complaint Handling',
            command: () => {
                setSelectedComponent(<AddEditComplaints onHide={onHide} />);
                setHeaderText('Add New Complaint Handling');
                setDialogVisible(true);
            }
        },
        {
            label: 'FAQs',
            command: () => {
                setSelectedComponent(<AddEditFaq onHide={onHide} />);
                setHeaderText('Add New FAQs');
                setDialogVisible(true);
            }
        }
    ];


    const header = (
        <img alt="Card" src="https://primefaces.org/cdn/primereact/images/usercard.png" />
    );
    const footer = (
        <>
            <a label="Save" icon="pi pi-check" className='custom-color' style={{ fontWeight: "bold" }} >
                View More <i className='pi pi-arrow-right pl-2'></i>
            </a>
        </>
    );

    const onHide = () => {
        setDialogVisible(false);
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(',', '');
    };


    useEffect(() => {
        GetAllProducts();
        GetAllImages();
        GetAllBanners();
        GetAllTips();
        GetOutageDegradation()
    }, []);

    return (
        <>
 <ToastContainer />
            <Dialog visible={dialogVisible} onHide={onHide} header={headerText} style={{ width: "60vw" }}>
                {selectedComponent}
            </Dialog>

            <div className='grid'>
                <div className='md:col-9'>
                    <h4 className='pt-2'>Welcome to Zindigi Knowledge Base System</h4>
                </div>
                <div className='md:col-3'>
                    {/* <SplitButton label="+ &nbsp;Add New" className='custom-btn w70' model={items} /> */}
                </div>
                <div className='xl:col-8 md:col-8 sm:col-12'>
                    <div className='slide-custom pl-5 pr-5' style={{ overflow: 'auto' }}>
                        {imagesList.length > 0 ? (
                            imagesList.length === 1 ? (
                                // If there is only one image, display it without the slider
                                <div>
                                    <img
                                        src={`${appUrl.baseUrl}${imagesList[0].imagePath}`}
                                        style={{ width: '100%', height: '298px', objectFit: 'contain' }}
                                        alt=""
                                    />
                                </div>
                            ) : (
                                // If there are multiple images, display them in the slider
                                <Slider {...settings}>
                                    {imagesList.map((imagePath, index) => (
                                        <div key={index}>
                                            <img
                                                src={`${appUrl.baseUrl}${imagePath.imagePath}`}
                                                style={{ width: '100%', height: '298px', }}
                                                alt=""
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            )
                        ) : (
                            // If there are no images, display a dummy image
                            <div>
                                <img
                                    src={banner}  // Replace with the path to your dummy image
                                    style={{ width: '100%', height: '298px',  }}
                                    alt="Dummy"
                                />
                            </div>
                        )}

                    </div>
                </div>
                <div className=' xl:col-4 md:col-4 sm:col-12 '>
                    <div className='tile-custom' style={{ height: '300px', borderTop: '10px solid #00B0AB' }}>
                        <div className='grid'>
                            <div className='md:col-12'>

                            </div>
                            <div className='md:col-4'> <img src={'assets/layout/images/Tip.png'} alt="logo" style={{ width: '90px' }} /></div>
                            <div className='md:col-8 pt-5'> <h5 className='mt-' style={{ fontWeight: "bold" }}>Tip of The Day</h5>
                                {/* <p className='color-gray'>Worried about your bills? Zindigi,
                                    has you covered!</p> */}
                            </div>
                        </div>


                        {tipsList.length > 0 && ((
                            <div className='color-gray' style={{ height: '120px', overflow: 'auto' }}>
                                <p dangerouslySetInnerHTML={{ __html: tipsList[0].description }}></p>
                            </div>
                        ))}
                        <div>
                            {/* <p className='custom-color' style={{ fontWeight: "bold" }}>Still waiting for Upgrade your account?</p> */}
                        </div>
                    </div>
                </div>
           
                <div className='xl:col-4 md:col-4 sm:col-12'>
                    <h4 className='m-0'>Outages/Degradations</h4>
                    <div className='grid tile-custom mt-3 p-0' style={{ height: "298px", overflow: "auto" }}>

                        {Array.isArray(outageList) && outageList.length > 0 ? (
                            outageList.map((outage, index) => (
                                <div key={index} className='md:col-12'>
                                    <div

                                        className="black-custom p-3"
                                        style={{
                                            borderRadius: '5px',
                                            background: outage.status === 'Resolved' ? '#F1FDF9' : '#FFFAF9'
                                        }}
                                    >
                                        <div className=''>
                                            <div className='grid'>
                                                <div className='md:col-1  pr-0 mt-2'>
                                                    <img src={'assets/layout/images/Kb/logo.png'} style={{ width: '20px' }} alt="logo" className='pt-1' />
                                                </div>
                                                <div className='md:col-5 pl-3 pr-0'>
                                                    <div className='flex'>

                                                        <h6 className='pt-2 pl-2 m-0'>{outage.productName}</h6>
                                                    </div>
                                                    <p className='pt-2 color-gray'>{outage.reason}</p>
                                                </div>
                                                <div className='md:col-6 text-right'>
                                                    <p className='pt-2 m-0' style={{ fontSize: '12px' }}>
                                                        {new Date(outage.createdDate).toLocaleString('en-GB', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                            hour12: true
                                                        })}
                                                    </p>
                                                    <p className='pt-2 m-0' style={{ fontSize: '12px' }}>
                                                        {outage.updatedDate
                                                            ? new Date(outage.updatedDate).toLocaleString('en-GB', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                                hour12: true
                                                            })
                                                            : '-'
                                                        }
                                                    </p>
                                                    <div className='pt-2'>
                                                        <button
                                                            className=''
                                                            style={{
                                                                width: "100px",
                                                                height: "30px",
                                                                borderRadius: '20px',
                                                                border: `1px solid ${outage.status === 'Resolved' ? '#4BC099' : '#F44336'}`,
                                                                color: outage.status === 'Resolved' ? '#4BC099' : '#F44336',
                                                                background: 'none'
                                                            }}
                                                        >
                                                            {outage.status}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='md:col-4'>
                                <p>No outages reported.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='xl:col-8 md:col-8 sm:col-12'>
                    <h4 className='m-0'>Promotions</h4>

                    <div className='slide-custom mt-3 pl-5 pr-5' style={{ overflow: 'auto' }}>
                        {imagesBanner.length > 0 ? (
                            imagesBanner.length === 1 ? (
                                // If there is only one image, display it without the slider
                                <div>
                                    <img
                                        src={`${appUrl.baseUrl}${imagesBanner[0].imagePath}`}
                                        style={{ width: '100%', height: '298px', objectFit: 'contain' }}
                                        alt=""
                                    />
                                </div>
                            ) : (
                                // If there are multiple images, display them in the slider
                                <Slider {...settings}>
                                    {imagesBanner.map((imagePath, index) => (
                                        <div key={index}>
                                            <img
                                                src={`${appUrl.baseUrl}${imagePath.imagePath}`}
                                                style={{ width: '100%', height: '298px', objectFit: 'contain' }}
                                                alt=""
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            )
                        ) : (
                            // If there are no images, display a dummy image
                            <div>
                                <img
                                    src={banner2}  // Replace with the path to your dummy image
                                    style={{ width: '100%', height: '298px', objectFit: 'contain' }}
                                    alt="Dummy"
                                />
                            </div>
                        )}

                    </div>



                </div>
            </div>



            <div className='grid'>
                <div className='md:col-12'>
                    <h4 className='m-0'>Channel</h4>
                </div>
                {cards.map(card => (
                    <div key={card.id} className='md:col-4 sm:col-12 '>
                        <Link className="black-custom" to={card.path}>
                            <div className='tile-custom ' style={{ height: "120px", }}>
                                <div className='grid'>
                                    <div className='md:col-2'>


                                        {card.id === 1 && <div className=''> <img src={'assets/layout/images/Kb/CallCenter.svg'} style={{ width: '100%' }} /></div>}
                                        {card.id === 2 && <div className=''> <img src={'assets/layout/images/Kb/SocialMedia.svg'} style={{ width: '100%' }} /></div>}
                                        {card.id === 3 && <div className=''> <img src={'assets/layout/images/Kb/InApp.svg'} style={{ width: '100%' }} /></div>}



                                    </div>
                                    <div className='md:col-8  sm:col-8  '>


                                        <h6 className='pt-2 m-0'> {card.content}</h6>
                                        <p className='m-0 color-gray'>{card.para}</p>

                                    </div>
                                    <div className='md:col-2 sm:col-2'>
                                        <div className='text-right' style={{ float: "right" }}>
                                            <div className='round'>
                                                <i className='pi pi-external-link custom-color'></i>
                                            </div> {/* <img src={'assets/layout/images/Kb/link.svg'} style={{ width: '40px' }} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}

            </div>

            <div className='grid  pb'>
                <div className='md:col-12'>
                    <h4 className=''>Products</h4>
                </div>
                <Product />
            </div>





        </>
    );
}

export default Dashboard;
