import React, { useState, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import appUrl from "../../../../constants/appUrl";
import axios from 'axios';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddEditFaq from '../components/AddEditFaq';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/laoder';
import { confirmDialog } from 'primereact/confirmdialog';
import FaqChannel from './FaqChannel';
import { useHistory } from 'react-router-dom';
const   Faqs = ({ searchQuery }) => {

    const [faqList, setFaqList] = useState([]);
    const [expandedId, setExpandedId] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [dialog, setDialog] = useState();
    const [editable, setEditable] = useState();
    const [res, setRes] = useState(null)
    const [loading, setLoading] = useState(false);
    const [rowselect, setRowselect] = useState(null)
    const [channelList,setChannelList]= useState()
    const history = useHistory();
    const getAllChannel = async () => {
       
        const token =localStorage.getItem('token')
    
        try {
          const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
          
          const data = await response.json();
          setChannelList(data);
        }
        catch (error) {
    
        }
      }
    useEffect(() => {
        getAllChannel()
      }, [])
    const GetAllFaq = async () => {
        const token = localStorage.getItem('token')
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/FAQ/GetAllFAQ`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setFaqList(data);
            setLoading(false);
        } catch (error) {
            error('Error fetching:', error);
        }
    };

    const isAdmin = localStorage.getItem('admin')

    const onHide = () => {
        setDialog(false)
    }
    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                faqList.filter((item) =>
                    item.question?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(faqList);
        }
    }, [searchQuery, faqList]);


    const accept = async (rowData) => {
        try {
            
            const response = await axios.delete(`${appUrl.baseUrl}api/FAQ/DeleteFAQByID/${rowData?.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
              
                return;
            }
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };
    const reject = (rowData) => {
        return
    }
    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>

                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this FAQs?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(rowData),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
            </div>
        );
    }
    const editAction = async (rowData) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(`${appUrl.baseUrl}api/FAQ/GetFAQById/${rowData.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }

            });
            const data = response.data;
            setEditable(true);
            setRowselect(data);
            setDialog(true);
        } catch (error) {
            error("Error fetching outage data by ID:", error);
            toast.error("Failed to fetch data for editing");
        }
    };

    useEffect(() => {
        GetAllFaq();
    }, [res]);

    return (
        <>
            <ToastContainer />
            <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit FAQ's" : "Add FAQ's"} style={{ width: "60vw" }}>
                <AddEditFaq editable={editable} dialog={dialog} setDialog={setDialog} onHide={onHide} GetAllFaq={GetAllFaq} setRes={setRes} rowData={rowselect} />
            </Dialog>



            <div className='grid'>
                <div className='xl:col-10 md:col-8 sm:col-8 col-4'>
                    <h5 style={{ fontWeight: "bold" }}>FAQ's</h5>
                </div>
               
                {isAdmin === 'true' || isAdmin === true ? (
                    <div className='xl:col-2 md:col-4 sm:col-4 col-8'>
                        <Button className="custom-btn w80" label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                    </div>
                ) :
                    null
                }
            </div>

            <div className='grid' >

                <div className='md:col-12 overflowX' >
                {loading && <Loader />}
                    <div className='table-customs'>
                    <TabView>
                        {channelList?.map((channel, index) => (
                            <TabPanel header={channel.channelName} key={index}>
                              <FaqChannel channelID={channel.channelID}/>
                            </TabPanel>
                        ))}
                    </TabView>
                       
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faqs;
